
import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Quicklink from '../Quicklink/Quicklink';
import Cards from '../Card/Card';
import '../../styles.css'
import Enquries from '../EnquriesForm/DownloadBrochure';




export default function Carousel() {
  const SilderImage1=
  [
   
    {
      img:"/images/banner7.jpg"
    },
    {
      img:"/images/banner8.jpg",
      url:"https://admissions.rvscas.ac.in/"
      
    },
    {
      img:"/images/banner9.png"
    },

  ]
  const SilderImage2=
  [
    {
      img:"/images/banner1.png"
    },
    {
      img:"/images/banner2.png"
    },
    {
      img:"/images/banner3.png"
    },
    {
      img:"/images/banner4.png"
    },
    {
      img:"/images/banner5.png"
    },{
      img:"/images/banner10.png"
    }


  ]
  const SliderView1=SilderImage1.map((value,index)=>
    <div>
      <a href={value.url} target='_blank' rel="noreferrer"><img src={value.img} alt={`Banner ${index+1}`} className="img-fluid"/></a>
    </div>
    )
    const SliderView2=SilderImage2.map((value,index)=>
    <div>
      <img src={value.img} alt={`Banner ${index+1}`} className="img-fluid"/>
    </div>
    )
  var settings = {
    dots: false,
    autoplay: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,


  };
 
  const [modalShow, setModalShow] = React.useState(false);

  
  return (
    <Container className='top'>
      <Row>
        <Row>
        <Col lg={8}>
          <div>
            <Slider {...settings}>
              {SliderView1}
            </Slider>
          </div>
        </Col>
        <Col lg={3}className='ms-4' >
          <Quicklink className="mx-auto mx-md-auto" />
        </Col>
        </Row>
        <Row className='my-3 '>
        <h3 className='HeadingStyle'>PHARMACY AT RVS – Change Your Future</h3>
        <p className='ParaStyle'>
          Pharmaceutical sciences is a life blood of health care, develop drugs for the treatment of
          human disease and help to live longer, healthier and happier. We provide students with
          instructions, guidance and mentorship. The programme is interlinked, practitioner, patient,
          medicine design, manufacture and drug action.
        </p>
        <p className='ParaStyle'>
          We go beyond just education and be facilitator of positive and inspiring environment where you can do your best work. Increase personal and professional expectation resourcing to obtain career potential. We help to scale your professional development and career readiness.
        </p>
      </Row>
      <Row>
        <div >
          <Slider {...settings}>
           {SliderView2}
          </Slider>
          </div>
          </Row>
          <h6 className='text-center my-5'>
            To know more about PHARMACY
            <p onClick={() => setModalShow(true)} className='DownloadBrochureButton'>Download Brochure</p>
          </h6>
            <Cards />
      </Row>
    
      
     
      
      <Enquries
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Container>
  )
}


