import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faHome } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row, Container } from "react-bootstrap";
import AboutMain from '../SideMenu/AboutMenu';


export default function Contact() {
    return (
        <Container className="Aboutpage">
            <Row>
                <Col lg={3}>
                    <AboutMain/>
                </Col>
                <Col lg={9}>
            <Card className='p-4'>
                <p>
                    <a href='/'>
                    <span className='mx-1 crumb'><FontAwesomeIcon icon={faHome}  className='mx-1'/></span>
                    </a>
                <span className='mx-1'>/</span>
                <span className='mx-1  crumb' href='/about'>About Us</span>
                <span className='mx-1'>/</span>
                <span className='mx-1  crumbcolor' href=''>contact</span></p> 
                
                <h4>Contact Us</h4>

<h6>Address</h6>
<span>RVS College of Pharmaceutical Sciences</span>
<span>242-B, Trichy Road, Sulur - 641402</span>
<span>Coimbatore, TamilNadu, India</span>

<hr/>
<h6>For Guidance/Admissions</h6>
<Row>
    <Col lg={6}>
    <span>Dr R.Venkatanarayanan</span><br/>
<span>Phone: +91 8012133444 </span><br/>
<span>Email: <span className="crumbcolor">venkatanarayanan@rvsgroup.com </span></span>
    </Col>
    <Col lg={6}>
    <span>Mr. Joby</span><br/>
<span>Phone: +91 9894167898</span><br/>
<span>Email:<span className='crumbcolor'> joby@rvsgroup.com</span></span>
    </Col>
</Row>
<hr/>
<h6>For Other Queries</h6>
<p>Office: 0422 2687 421  / 603</p>


</Card>
   </Col>
</Row>

              
            
            </Container>
    )
}


